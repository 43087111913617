
import { Vue } from "vue-property-decorator";
import { parseTime } from "@/utils";
import api from "@/api/visitorrecord";

export default class extends Vue {
  private calendarShow = false;
  private loading = false;
  private finished = false;
  private refreshing = false;
  toptime = new Date();

  visitorList = [] as any[];
  page = {
    pageSize: 10,
    pageNum: 1,
  };
  private date = parseTime(new Date(), "{m}月{d}日 星期{a}");

  onLoad() {
    setTimeout(() => {
      if (this.refreshing) {
        this.visitorList = [];
        this.page.pageNum = 1;
        this.refreshing = false;
      }

      api
        .workeruserinfo({
          projectId: this.$store.state.project.projectinfo.projectId,
          ...this.page,
          date: parseTime(this.toptime, "{y}-{m}-{d}"),
        })
        .then((res: any) => {
          if (res.code !== 0) {
            (this as any).$toast.fail(res.msg);
          } else {
            this.visitorList = [...this.visitorList, ...res.data.records];
            this.page.pageNum += 1;
            this.loading = false;
            if (res.data.current >= res.data.pages) {
              this.finished = true;
            }
          }
        });
    }, 1000);
  }
  onRefresh() {
    // 清空列表数据
    this.finished = false;
    // 重新加载数据
    // 将 loading 设置为 true，表示处于加载状态
    this.loading = true;
    this.onLoad();
  }
  fConfirmDate(date: Date) {
    this.toptime = date;
    this.date = parseTime(date, "{m}月{d}日 星期{a}");
    this.calendarShow = false;
    this.onRefresh();
  }
  fOpenCalendar() {
    this.calendarShow = true;
  }
}
