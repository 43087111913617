import request from "@/utils/request";

export default {
  // 获取用户基本信息
  workeruserinfo(args: any) {
    return request({
      url: "/project/mobile/visitor/page",
      method: "get",
      params: args,
    });
  },
};
